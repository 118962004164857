<template>
    <div id="content">
        <!-- 头部 -->
        <header>
            <img @click="goHome" src="../../assets/img/go.png" alt="">
            <span style="padding-left: 33%" class="fs">公司新闻</span>
        </header>
        <!-- 第二部分 -->
        <div class="photo_img">
            <p class="fb">公司新闻</p>
        </div>
        <!-- 新闻列表 -->
        <div class="newContent">
            <li v-for="(item,index) in newList" @click="goDetail(item.id)" :key="index">
                <div class="newList clearfix">
                    <img class="fl" :src="item.imgUrl" alt="">
                    <div class="newRight fl">
                        <p class="newTitle fb" style="margin-top: .1rem;">{{item.title}}</p>
                        <p class="new_text fs">

                        </p>
                        <p class="new_time fs">{{item.createTime}}</p>
                    </div>
                </div>
            </li>
        </div>

        <!-- 底部 -->
        <footerEn></footerEn>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {
            newList: [],
            slide: true,
            pageNum: 1,
            pageSize: 99,
        };
    },
    created() {
        let _this = this;
        _this.getNewList();
        // window.addEventListener('scroll', function () {
        //     if ($(window).scrollTop() + $(window).height() >= $(document).height() - 300) {
        //         if (_this.slide) {
        //             _this.slide = false;
        //             _this.pageNum++;
        //             _this.getNewList();
        //         }
        //     }
        // });
    },
    mounted() {
        if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            this.$router.push('/companyNews')
        } else {
            window.addEventListener('resize', this.remScale());

        }
    },
    methods: {
        goHome(){
            this.$router.push('/indexEn');
        },
        remScale() {
            var winW = document.documentElement.clientWidth;
            winW = winW > 750 ? 750 : winW;
            document.documentElement.style.fontSize = winW / 750 * 100 + 'px'
        },
        getNewList() {
            let _this = this;
            $.ajax({
                type: 'post',
                url: _this.requestUrl + 'getArticleList',
                data: {
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize,
                },
                dataType: 'json',
                success: function (res) {
                    if (res.code == 1000) {
                        _this.newList = _this.newList.concat(res.data);
                        _this.sw = true;
                    }
                }
            })
        },
        goDetail(id){
            this.$router.push({
                path: '/newDetailsOneEn',
                query: {
                    id: id
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../assets/css/companyNews_en.scss";
</style>
